// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";

// @mui icons
import SaveIcon from "@mui/icons-material/Save";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EditSprayInfoCard from "examples/Cards/InfoCards/EditSprayInfoCard";
// import ProfilesList from "examples/Lists/ProfilesList";
import ChemicalsListEdit from "examples/Lists/ChemicalListEdit";
import BlockListEdit from "examples/Lists/BlockListEdit";
import WeatherList from "examples/Lists/WeatherList";
// Overview page components
import Header from "layouts/spraydetail/components/Header";
import SprayerSettings from "layouts/editspray/components/SprayerSettings";
import WaterSettings from "layouts/editspray/components/WaterSettings";

import { useLocation, useParams, useNavigate } from "react-router-dom";

// React
import { useState } from "react";

// Firebase
import { doc, setDoc } from "firebase/firestore";
import { db } from "firebaseDb";

function Overview() {
  const location = useLocation();
  const { data } = location.state;
  const { sprayId } = useParams();
  const [sprayInfo, setSprayInfo] = useState(data);
  const [loading, setLoading] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setopenModal(true);
  const handleClose = () => setopenModal(false);

  const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const updateSpray = () => {
    if (
      sprayInfo.siteCode === "Orchard" ||
      sprayInfo.chemicals.length < 1 ||
      sprayInfo.blockVarieties.length < 1 ||
      sprayInfo.sprayer.length < 1 ||
      sprayInfo.operatorWorker.length < 1 ||
      !sprayInfo.sun ||
      !sprayInfo.wind ||
      !sprayInfo.temperature
    ) {
      handleOpen();
    } else {
      setLoading(true);
      const sprayRef = doc(db, "Sprays", sprayInfo.key);
      setDoc(
        sprayRef,
        { ...sprayInfo, status: sprayInfo.sprayDate ? "Sprayed" : "Ready to Spray" },
        { merge: true }
      ).then(() => {
        setLoading(false);
        navigate("/sprays");
      });
    }
  };

  if (!loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox sx={modalstyle}>
            <MDTypography id="modal-modal-title" variant="h6" component="h2">
              <Icon>warning</Icon>Information Missing!
            </MDTypography>
            <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
              You are missing some vital information for this spray entry. Please check and try
              again. (Weather, Orchard, Chemical, Blocks, Sprayer, Setting, Operator are all
              required)
            </MDTypography>
          </MDBox>
        </Modal>
        <Header>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                <EditSprayInfoCard
                  setSprayInfo={setSprayInfo}
                  title="spray information"
                  info={{
                    date: sprayInfo.sprayDate,
                    sitecode: sprayInfo.siteCode,
                    orchard: sprayInfo.siteName,
                    operators:
                      typeof sprayInfo.operatorWorker === "string"
                        ? sprayInfo.operatorWorker
                        : sprayInfo.operatorWorker,
                    growth: sprayInfo.growthStage,
                    hectarage: `${sprayInfo.hectares} ha`,
                  }}
                  action={{ route: `/editweedspray/${sprayId}`, tooltip: "Edit Weed Spray" }}
                  shadow={false}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SprayerSettings
                  title="sprayer settings"
                  sprayInfo={sprayInfo}
                  setSprayInfo={setSprayInfo}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <WaterSettings
                  title="water settings"
                  sprayInfo={sprayInfo}
                  hectares={sprayInfo.hectares}
                  setSprayInfo={setSprayInfo}
                />
              </Grid>

              <Grid item xs={12} xl={12}>
                <ChemicalsListEdit
                  title="Chemical Info"
                  sprayInfo={sprayInfo}
                  shadow={false}
                  setSprayInfo={setSprayInfo}
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MDTypography>Show Block Information</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BlockListEdit
                      title="Block Info"
                      sprayInfo={sprayInfo}
                      shadow={false}
                      setSprayInfo={setSprayInfo}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} xl={12}>
                <WeatherList
                  title="Weather"
                  sprayInfo={sprayInfo}
                  shadow={false}
                  setSprayInfo={setSprayInfo}
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                <Box sx={{ textAlign: "center" }}>
                  <MDButton
                    variant="contained"
                    color="success"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      updateSpray();
                    }}
                  >
                    Save Updated Spray Record
                  </MDButton>
                </Box>
              </Grid>
              <Grid item xs={12} xl={12}>
                <Box sx={{ textAlign: "center" }}>
                  {sprayInfo.signature ? <img alt="signature" src={sprayInfo.signature} /> : null}
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  {sprayInfo.signature ? `Date: ${sprayInfo.signatureDate}` : null}
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </Header>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Box mt={5} mb={3} textAlign="center">
          <CircularProgress color="success" />
        </Box>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
