// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function WaterSettings({ title, sprayInfo, hectares }) {
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1.3rem", paddingBottom: "10px" }}
        >
          {title}
        </MDTypography>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} lineHeight={1.25}>
        <MDBox display="flex" alignItems="left" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="text"
              textTransform="uppercase"
              sx={{ margin: "7px", fontSize: "1rem" }}
            >
              Water Rate:
            </MDTypography>
          </MDBox>
          <MDBox width="50%" ml={0.5}>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ fontSize: "1rem" }}
            >
              {`${sprayInfo.sprayRate} L/Hec`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="text"
              textTransform="uppercase"
              sx={{ margin: "7px", fontSize: "1rem" }}
            >
              Water Usage:
            </MDTypography>
          </MDBox>
          <MDBox width="50%" ml={0.5}>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ fontSize: "1rem" }}
            >
              {`${Math.round(Number(hectares) * Number(sprayInfo.sprayRate))}L`}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="text"
              textTransform="uppercase"
              sx={{ margin: "7px", fontSize: "1rem" }}
            >
              Full Tanks:
            </MDTypography>
          </MDBox>
          <MDBox width="50%" ml={0.5}>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ fontSize: "1rem" }}
            >
              {`${Math.floor(
                (Number(hectares) * Number(sprayInfo.sprayRate)) / Number(sprayInfo.tankCapacity)
              )} x ${sprayInfo.tankCapacity}L`}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="text"
              textTransform="uppercase"
              sx={{ margin: "7px", fontSize: "1rem" }}
            >
              Part Tank:
            </MDTypography>
          </MDBox>
          <MDBox width="50%" ml={0.5}>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ fontSize: "1rem" }}
            >
              {`${(
                Number(hectares) * Number(sprayInfo.sprayRate) -
                Math.floor(
                  (Number(hectares) * Number(sprayInfo.sprayRate)) / sprayInfo.tankCapacity
                ) *
                  sprayInfo.tankCapacity
              ).toFixed(2)}L`}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props
WaterSettings.defaultProps = {};

// Typechecking props
WaterSettings.propTypes = {
  title: PropTypes.string.isRequired,
  sprayInfo: PropTypes.objectOf.isRequired,
  hectares: PropTypes.number.isRequired,
};

export default WaterSettings;
