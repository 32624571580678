/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDBadge from "components/MDBadge";

import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { db } from "firebaseDb";
import { query, where, onSnapshot, collection } from "firebase/firestore";
import { Icon } from "@mui/material";

export default function data(handleOpenDialog, status) {
  const navigate = useNavigate();
  const [sprays, setSprays] = useState([]);
  const [isCopyButtonClicked, setIsCopyButtonClicked] = useState(false);

  const Blocks = ({ blocks }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={1} lineHeight={3} sx={{ maxWidth: "150px" }}>
        {blocks.split(",").map((b) => (
          <MDTypography key={b} display="block" variant="button" fontWeight="medium">
            {b}
          </MDTypography>
        ))}
      </MDBox>
    </MDBox>
  );

  // Get Sprays Data
  useEffect(() => {
    const eightMonthsAgo = new Date();
    eightMonthsAgo.setMonth(eightMonthsAgo.getMonth() - 8);
    const sprayRef = collection(db, "Weedsprays");
    const q = query(
      sprayRef,
      where("created", ">=", eightMonthsAgo),
      where("archive", "==", false)
    );
    onSnapshot(q, (querySnapshot) => {
      const tempsprays = [];
      querySnapshot.forEach((docu) => {
        if (!docu.data().archive) {
          tempsprays.push({
            sprayID: docu.data().sprayInstructionId,
            date: docu.data().weekEnding
              ? docu.data().weekEnding.toDate().toLocaleString("en-GB", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              : "",
            status: docu.data().status,
            weedspray: docu.data().weedspray,
            operator: docu.data().operatorWorker.join("\r\n"),
            chemicals: docu
              .data()
              .chemicals.map((c) => c.chemicalName)
              .join("\r\n"),
            blocks: <Blocks blocks={docu.data().area} />,
            action: (
              <MDBox>
                <MDButton
                  size="medium"
                  iconOnly
                  color="info"
                  disabled={docu.data().sprayInstructionId < 20000}
                  onClick={(event) => {
                    event.stopPropagation(); // Stop the event from propagating to the row click event handler
                    setIsCopyButtonClicked(true);
                    navigate("/weedsprays/cloneweedspray", {
                      state: {
                        data: {
                          ...docu.data(),
                          weekEnding: docu.data().weekEnding ? docu.data().weekEnding.toDate() : "",
                        },
                      },
                    });
                  }}
                >
                  <Icon>copy</Icon>
                </MDButton>
                <MDButton
                  size="medium"
                  iconOnly
                  color="warning"
                  sx={{ marginLeft: "3px" }}
                  disabled={docu.data().sprayInstructionId < 20000}
                  onClick={(event) => {
                    event.stopPropagation(); // Stop the event from propagating to the row click event handler
                    setIsCopyButtonClicked(true);
                    navigate(`/editweedspray/${docu.data().sprayInstructionId}`, {
                      state: {
                        data: {
                          ...docu.data(),
                          weekEnding: docu.data().weekEnding ? docu.data().weekEnding.toDate() : "",
                          key: docu.id,
                        },
                      },
                    });
                  }}
                >
                  <Icon>edit</Icon>
                </MDButton>
                <MDButton
                  size="medium"
                  iconOnly
                  sx={{ marginLeft: "3px" }}
                  color="error"
                  disabled={docu.data().sprayInstructionId < 20000}
                  onClick={(event) => {
                    event.stopPropagation(); // Stop the event from propagating to the row click event handler
                    setIsCopyButtonClicked(true);
                    handleOpenDialog(docu.data().sprayInstructionId);
                  }}
                >
                  <Icon>delete</Icon>
                </MDButton>
              </MDBox>
            ),
          });
        }
      });
      setSprays(tempsprays);
    });
  }, []);

  if (status === "Ready to Spray") {
    return {
      columns: [
        { Header: "spray id", accessor: "sprayID", align: "left" },
        { Header: "area", accessor: "blocks", align: "center" },
        { Header: "operator", accessor: "operator", align: "center" },
        { Header: "chemicals", accessor: "chemicals", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
      ],

      rows: sprays,
      clickEvent: isCopyButtonClicked,
    };
  }
  return {
    columns: [
      { Header: "spray id", accessor: "sprayID", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "area", accessor: "blocks", align: "center" },
      { Header: "operator", accessor: "operator", align: "center" },
      { Header: "chemicals", accessor: "chemicals", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: sprays,
    clickEvent: isCopyButtonClicked,
  };
}
