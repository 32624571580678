import { useState, useEffect } from "react";

// DatePicker components
import dayjs from "dayjs";

// firebase functions
import { db } from "firebaseDb";
import { getDocs, query, collection, orderBy, addDoc } from "firebase/firestore";

import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Orchard Selection
import Orchard from "layouts/sprays/clonespray/components/Orchard";

// Chemical Selection
import Chemicals from "layouts/sprays/clonespray/components/Chemicals";

// Settings Selection
import Settings from "layouts/sprays/clonespray/components/Settings";

require("dayjs/locale/en-au");

function CloneSpray() {
  // Accessing Window
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);
  const [sidenavPadding, setSideNavPadding] = useState("230");
  const [openModal, setopenModal] = useState(false);
  const [targetareas, settargetAreas] = useState([]);
  const [operators, setOperators] = useState([]);
  const [chemicals, setChemicals] = useState([]);
  const [stages, setStages] = useState([]);
  const [units, setUnits] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [sprayers, setSprayers] = useState([]);
  const [settings, setSettings] = useState([]);
  const navigate = useNavigate();

  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear() - 2000).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

  const [spray, setSpray] = useState({
    ...state.data,
    sprayDate: "",
    sprayInstructionId: formattedDate,
    status: "Ready to Spray",
  });

  const handleOpen = () => setopenModal(true);
  const handleClose = () => setopenModal(false);

  const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const steps = [
    {
      label: "Select Orchard and Blocks",
      component: <Orchard spray={spray} setSpray={setSpray} />,
    },
    {
      label: "Chemicals",
      component: (
        <Chemicals
          spray={spray}
          setSpray={setSpray}
          chemicals={chemicals}
          measures={measures}
          units={units}
          stages={stages}
          sprayers={sprayers}
          handlers={operators.filter((op) => op.approvedHandler === true)}
        />
      ),
    },
    {
      label: "Operator and Notes",
      component: (
        <Settings
          spray={spray}
          setSpray={setSpray}
          settings={settings}
          chemicals={chemicals}
          measures={measures}
          units={units}
          stages={stages}
          targetareas={targetareas}
          sprayers={sprayers}
          operators={operators}
        />
      ),
    },
  ];

  const saveSpray = () => {
    if (
      spray.siteCode === "Orchard" ||
      spray.chemicals.length < 1 ||
      spray.blockVarieties.length < 1 ||
      spray.sprayer.length < 1 ||
      !spray.setting ||
      spray.operatorWorker.length < 1
    ) {
      handleOpen();
    } else {
      setLoading(true);
      const sprayRef = collection(db, "Sprays");
      // getDocs(sprayRef).then((querySnapshot) => {
      //   querySnapshot.forEach((docu) => {
      //     const docRef = doc(db, "Sprays", docu.id);
      //     const olddate = docu.data().sprayDate;
      //     console.log(olddate);
      //     const newdate = Timestamp.fromDate(new Date(olddate));
      //     setDoc(docRef, { sprayDate: newdate }, { merge: true });
      //   });
      // });

      addDoc(sprayRef, spray).then(() => {
        setLoading(false);
        navigate(`/sprays/${spray.sprayInstructionId}`);
      });
    }
  };

  // Setting Locale
  dayjs.locale("en-au");

  useEffect(() => {
    // A function that sets the padding
    function handleSidenavPadding() {
      setSideNavPadding(window.innerWidth > 1200 ? "230px" : "60px");
    }

    /**
     The event listener that's calling the padding function when resizing the window.
    */
    window.addEventListener("resize", handleSidenavPadding);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleSidenavPadding();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleSidenavPadding);
  }, [location]);

  useEffect(() => {
    // Fetch Data

    const growthRef = collection(db, "GrowthStage");
    const qg = query(growthRef, orderBy("growthStage", "asc"));
    getDocs(qg).then((querySnapshot) => {
      const tempstages = [];
      querySnapshot.forEach((docu) => {
        tempstages.push({
          key: docu.id,
          growthStage: docu.data().growthStage,
        });
      });
      setStages(tempstages);
    });

    const areaRef = collection(db, "TargetArea");
    const qa = query(areaRef, orderBy("targetArea", "asc"));
    getDocs(qa).then((querySnapshot) => {
      const tempareas = [];
      querySnapshot.forEach((docu) => {
        tempareas.push({
          key: docu.id,
          targetArea: docu.data().targetArea,
        });
      });
      settargetAreas(tempareas);
    });

    const operatorRef = collection(db, "Operators");
    const qo = query(operatorRef, orderBy("name", "asc"));
    getDocs(qo).then((querySnapshot) => {
      const tempoperators = [];
      querySnapshot.forEach((docu) => {
        if (!docu.data().archive) {
          tempoperators.push({
            key: docu.id,
            name: docu.data().name,
            approvedHandler: !!docu.data().approvedHandler,
          });
        }
      });
      setOperators(tempoperators);
    });

    const chemRef = collection(db, "Chemicals");
    const qc = query(chemRef, orderBy("chemicalName", "asc"));
    getDocs(qc).then((querySnapshot) => {
      const tempchems = [];
      querySnapshot.forEach((docu) => {
        if (!docu.data().weedspray) {
          tempchems.push({
            key: docu.id,
            chemicalName: docu.data().chemicalName,
            sprayRate: docu.data().qtyPerHectare,
            unit: docu.data().unit,
            measure: "Hectare",
            cost: docu.data().cost,
          });
        }
      });
      setChemicals(tempchems);
    });

    const tractorRef = collection(db, "Tractors");
    const qt = query(tractorRef, orderBy("sprayerName", "asc"));
    getDocs(qt).then((querySnapshot) => {
      const tempsprayers = [];
      querySnapshot.forEach((docu) => {
        if (!docu.data().weedsprayer) {
          tempsprayers.push({
            key: docu.id,
            sprayer: docu.data().sprayerName,
            tankCapacity: docu.data().tankCapacity,
            settings: docu.data().settings,
          });
        }
      });
      setSprayers(tempsprayers);
    });

    const unitRef = collection(db, "Units");
    const qu = query(unitRef, orderBy("unit", "asc"));
    getDocs(qu).then((querySnapshot) => {
      const tempunits = [];
      querySnapshot.forEach((docu) => {
        tempunits.push({
          key: docu.id,
          unit: docu.data().unit,
        });
      });
      setUnits(tempunits);
    });

    const measureRef = collection(db, "Measures");
    const qm = query(measureRef, orderBy("measure", "asc"));
    getDocs(qm).then((querySnapshot) => {
      const tempmeasures = [];
      querySnapshot.forEach((docu) => {
        tempmeasures.push({
          key: docu.id,
          measure: docu.data().measure,
        });
      });
      setMeasures(tempmeasures);
    });

    const settingRef = collection(db, "Settings");
    const qss = query(settingRef, orderBy("waterRate", "asc"));
    getDocs(qss).then((querySnapshot) => {
      const tempsettings = [];
      querySnapshot.forEach((docu) => {
        tempsettings.push({
          key: docu.id,
          setting: `${docu.data().waterRate}/${docu.data().gearSetting}/${docu.data().rpm}/${
            docu.data().pressure
          }/${docu.data().nozzleSetting}/${docu.data().fanSetting}/${docu.data().travelSpeed}`,
          fanSetting: docu.data().fanSetting,
          gearSetting: docu.data().gearSetting,
          nozzleSetting: docu.data().nozzleSetting,
          pressure: docu.data().pressure,
          rpm: docu.data().rpm,
          travelSpeed: docu.data().travelSpeed,
          waterRate: docu.data().waterRate,
        });
      });
      setSettings(tempsettings);
    });
  }, []);

  return (
    <MDBox py={12}>
      <Card sx={{ paddingTop: "40px", width: "80%", marginLeft: sidenavPadding }}>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox sx={modalstyle}>
            <MDTypography id="modal-modal-title" variant="h6" component="h2">
              <Icon>warning</Icon>Information Missing!
            </MDTypography>
            <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
              You are missing some vital information for this spray entry. Please check and try
              again. (Orchard, Chemical, Blocks, Sprayer, Setting, Operator are all required)
            </MDTypography>
          </MDBox>
        </Modal>
        <Grid
          container
          justifyContent="center"
          xs={10}
          lg={10}
          mx="auto"
          textAlign="center"
          maxWidth="xl"
        >
          <Box sx={{ width: "100%" }}>
            <MDTypography variant="h3" mb={1}>
              Clone Spray Record
            </MDTypography>

            {steps.map((step) => step.component)}
          </Box>
        </Grid>
        <Grid item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MDBox width="100%" method="post">
            <MDBox p={3}>
              <Grid item justifyContent="center" xs={12} my={2}>
                {!loading && (
                  <MDButton variant="gradient" color="info" fullWidth onClick={() => saveSpray()}>
                    <Icon
                      sx={{
                        fontWeight: "bold",
                        color: ({ palette: { dark } }) => dark.main,
                        paddingRight: 2,
                      }}
                    >
                      add
                    </Icon>
                    Save Spray
                  </MDButton>
                )}
                {loading && (
                  <MDButton disabled variant="gradient" color="warning" fullWidth>
                    <CircularProgress />
                  </MDButton>
                )}
              </Grid>
            </MDBox>
          </MDBox>
        </Grid>
      </Card>
    </MDBox>
  );
}

export default CloneSpray;
