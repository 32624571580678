// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// DatePicker components
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState } from "react";

// firebase functions
import { updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from "firebaseDb";

require("dayjs/locale/en-au");

// Setting Locale
dayjs.locale("en-au");

function EditWeedSprayInfoCard({ title, info, shadow, setSprayInfo, sprayInfo, date }) {
  const [loading, setLoading] = useState(false);
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => {
    if (label === "date") {
      if (loading) {
        return (
          <MDBox key={label} display="flex" justifyContent="center" py={1} pr={2}>
            <CircularProgress />
          </MDBox>
        );
      }
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Week Ending"
              value={date}
              onChange={(newDate) => {
                if (newDate) {
                  setLoading(true);
                  const firebaseTimestamp = Timestamp.fromDate(new Date(newDate));
                  setSprayInfo((prevState) => ({
                    ...prevState,
                    weekEnding: newDate, // Store the new date in state
                  }));
                  const sprayRef = doc(db, "Weedsprays", sprayInfo.key);

                  updateDoc(sprayRef, { weekEnding: firebaseTimestamp }) // Use the Firebase Timestamp
                    .then(() => {
                      setLoading(false);
                      console.log("Document successfully updated!");
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.error("Error updating document: ", error);
                      alert("Error");
                    });
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </MDBox>
      );
    }
    if (label === "hectarage") {
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <TextField value={values[key]} label={label.toUpperCase()} fullWidth disabled />
        </MDBox>
      );
    }
    if (label === "area") {
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <TextField value={values[key]} label={label.toUpperCase()} fullWidth disabled />
        </MDBox>
      );
    }
    return (
      <MDBox key={label} display="flex" py={1} pr={2}>
        <TextField
          sx={{ width: "100%" }}
          value={values[key]}
          label={label.toUpperCase()}
          fullWidth
          onChange={(e) => {
            setSprayInfo((prevState) => ({
              ...prevState,
              [label]: e.target.value,
            }));
          }}
        />
      </MDBox>
    );
  });

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", backgroundColor: "#b2d6bb" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1rem" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
        <MDBox>{renderItems}</MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props
EditWeedSprayInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props
EditWeedSprayInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.any.isRequired,
  shadow: PropTypes.bool,
  setSprayInfo: PropTypes.func.isRequired,
};

export default EditWeedSprayInfoCard;
