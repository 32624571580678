import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TableContainer } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";

// DatePicker components
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

// firebase functions
import { setDoc, doc, Timestamp } from "firebase/firestore";
import { db } from "firebaseDb";

require("dayjs/locale/en-au");

// Setting Locale
dayjs.locale("en-au");

function WeedSprayBlockList({ title, sprayInfo, shadow, setSprayInfo }) {
  const [loading, setLoading] = useState(false);
  const filteredOrchards = sprayInfo.orchards
    .filter((o) => o.checked) // Filter orchards based on checked property
    .map((orchard) => ({
      ...orchard,
      blocks: orchard.blocks
        .filter((b) => b.checked) // Filter blocks based on checked property
        .map((block) => ({
          ...block,
          varieties: block.varieties.filter((v) => v.blockVarietyActive), // Filter varieties based on blockVarietyActive property
        })),
    }));

  const weekEndingDate = dayjs.unix(sprayInfo.weekEnding?.seconds); // Convert to dayjs date
  const startOfWeek = weekEndingDate.subtract(6, "days"); // 7 days before the week ending
  const endOfWeek = weekEndingDate; // week ending date

  const saveToFirestore = async ({ block, row, calculatedCost, totalVolume, totalHectares }) => {
    // Convert the new date to a Firestore Timestamp
    const date = dayjs.unix(block.sprayDate?.seconds);
    const isoStringDate = block.sprayDate ? date.toISOString() : "";
    const weedsprayDate = new Date(isoStringDate);
    const day = String(weedsprayDate.getDate()).padStart(2, "0");
    const year = String(weedsprayDate.getFullYear() - 2000).padStart(2, "0");
    const month = String(weedsprayDate.getMonth() + 1).padStart(2, "0");
    const formattedID = `${year}${month}${day}${row.siteCode}${block.blockCode}${sprayInfo.chemicals[0].chemicalName}`;

    try {
      // Create the new invoice object
      const newInvoice = {
        weedspray: true,
        key: formattedID,
        siteName: row.siteName,
        siteCode: row.siteCode,
        date: block.sprayDate,
        chemicalName: sprayInfo.chemicals[0].chemicalName,
        chemicalcost: block.actualChemical
          ? block.actualChemical * sprayInfo.chemicals[0].cost
          : Number(calculatedCost), // Ensure calculatedCost is defined earlier in the component
        block: block.blockCode,
        totalused: Number(block.actualChemical ? block.actualChemical : totalVolume),
        hectares: Number(totalHectares.toFixed(2)),
        unitCost: Number(sprayInfo.chemicals[0].cost),
      };

      // Firestore references
      const invoiceRef = doc(db, "WeedsprayInvoices", formattedID);
      const sprayRef = doc(db, "Weedsprays", sprayInfo.key);

      // Set loading to true before saving data
      setLoading(true);

      // Save the new invoice and update the spray data
      await Promise.all([
        setDoc(invoiceRef, newInvoice),
        setDoc(sprayRef, {
          ...sprayInfo, // Ensure sprayInfo has all the necessary properties
        }),
      ]);

      console.log("Firestore successfully updated!");
    } catch (error) {
      console.error("Error updating Firestore: ", error);
      alert("Error updating Firestore.");
    } finally {
      // Set loading to false after completion
      setLoading(false);
    }
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            {filteredOrchards.map((row, index) => (
              <Accordion key={row.siteCode} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <MDTypography style={{ flex: 1 }}>
                      {`${row.siteCode} - ${row.siteName}`}
                    </MDTypography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table aria-label="blocks table">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Block
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Varieties
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Hectares
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Liters
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Actual Liters
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Date Sprayed
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Save
                          </TableCell>
                        </TableRow>
                        {row.blocks
                          // Filter out blocks where total hectares is 0
                          .filter((block) => {
                            const totalHectares = block.varieties.reduce(
                              (sum, item) => sum + parseFloat(item.hectares),
                              0
                            );
                            return totalHectares > 0;
                          })
                          // Sort blocks by blockCode
                          .sort((a, b) => a.blockCode.localeCompare(b.blockCode))
                          .map((block, blockIndex) => {
                            const totalHectares = block.varieties.reduce(
                              (sum, item) => sum + parseFloat(item.hectares),
                              0
                            );
                            const totalVolume = (sprayInfo.sprayRate * totalHectares).toFixed(0);
                            const calculatedValue = (
                              (sprayInfo.sprayRate * totalHectares) /
                              sprayInfo.tankCapacity
                            ).toFixed(1);
                            const calculatedCost = (
                              totalVolume * sprayInfo.chemicals[0].cost
                            ).toFixed(2);

                            const date = dayjs.unix(block.sprayDate?.seconds);
                            const isoStringDate = block.sprayDate ? date.toISOString() : "";

                            return (
                              <TableRow
                                key={block.blockCode}
                                align="right"
                                sx={{
                                  "&:last-child td, &:last-child th": { border: 0 },
                                  backgroundColor: blockIndex % 2 === 0 ? "#b2d6bb" : "#e3fad4",
                                }}
                              >
                                <TableCell align="left">{block.blockCode}</TableCell>
                                <TableCell align="left">
                                  {block.varieties.map((c) => c.varietyName).join("\r\n")}
                                </TableCell>
                                <TableCell align="left">{totalHectares.toFixed(2)}</TableCell>
                                <TableCell align="left">
                                  {totalVolume}L ({calculatedValue} Tanks)
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    InputProps={{
                                      style: {
                                        backgroundColor: row.disabled ? "lightgrey" : "lightgreen",
                                      },
                                    }}
                                    value={block.actualChemical}
                                    type="number"
                                    onChange={(e) => {
                                      const updatedValue = e.target.value;

                                      setSprayInfo((prevState) => {
                                        // Find the correct orchard
                                        const updatedOrchards = prevState.orchards.map(
                                          (orchard) => {
                                            if (orchard.siteCode === row.siteCode) {
                                              // Find the correct block within the orchard
                                              const updatedBlocks = orchard.blocks.map(
                                                (orchardBlock) => {
                                                  if (orchardBlock.blockCode === block.blockCode) {
                                                    // Update the actualChemical property for this block
                                                    return {
                                                      ...orchardBlock,
                                                      actualChemical: updatedValue,
                                                    };
                                                  }
                                                  return orchardBlock; // Return unchanged blocks
                                                }
                                              );

                                              // Return the updated orchard with modified blocks
                                              return {
                                                ...orchard,
                                                blocks: updatedBlocks,
                                              };
                                            }
                                            return orchard; // Return unchanged orchards
                                          }
                                        );

                                        // Return the updated state
                                        return {
                                          ...prevState,
                                          orchards: updatedOrchards,
                                        };
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <MDBox key={block} display="flex" py={1} pr={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        label="Spray Date"
                                        value={isoStringDate}
                                        onChange={(newDate) => {
                                          if (!newDate) return;

                                          // Update local state
                                          setSprayInfo((prevState) => {
                                            const firebaseTimestamp = Timestamp.fromDate(
                                              new Date(newDate)
                                            );
                                            const updatedOrchards = prevState.orchards.map(
                                              (orchard) => {
                                                if (orchard.siteCode === row.siteCode) {
                                                  // Update the sprayDate for the correct block
                                                  const updatedBlocks = orchard.blocks.map(
                                                    (orchardBlock) => {
                                                      if (
                                                        orchardBlock.blockCode === block.blockCode
                                                      ) {
                                                        return {
                                                          ...orchardBlock,
                                                          sprayDate: firebaseTimestamp,
                                                        };
                                                      }
                                                      return orchardBlock;
                                                    }
                                                  );

                                                  return {
                                                    ...orchard,
                                                    blocks: updatedBlocks,
                                                  };
                                                }
                                                return orchard;
                                              }
                                            );

                                            return {
                                              ...prevState,
                                              orchards: updatedOrchards,
                                            };
                                          });
                                        }}
                                        minDate={startOfWeek}
                                        maxDate={endOfWeek}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                    </LocalizationProvider>
                                  </MDBox>
                                </TableCell>
                                {!loading ? (
                                  <MDButton
                                    sx={{
                                      marginBottom: "15px",
                                      marginTop: "15px",
                                      marginRight: "15px",
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                      if (block.sprayDate) {
                                        // Call the saveToFirestore function to handle Firestore operations
                                        saveToFirestore({
                                          block,
                                          row,
                                          calculatedCost,
                                          totalVolume,
                                          totalHectares,
                                        });
                                      } else {
                                        alert("You need to enter a spray date.");
                                      }
                                    }}
                                  >
                                    <MDTypography>
                                      <Icon>save</Icon>
                                    </MDTypography>
                                  </MDButton>
                                ) : (
                                  <MDBox
                                    key={block}
                                    display="flex"
                                    justifyContent="center"
                                    py={1}
                                    pr={2}
                                  >
                                    <CircularProgress />
                                  </MDBox>
                                )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

WeedSprayBlockList.defaultProps = {
  shadow: true,
};

WeedSprayBlockList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sprayInfo: PropTypes.object.isRequired,
};

export default WeedSprayBlockList;
