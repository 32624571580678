// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Card from "@mui/material/Card";

function Chemicals({ spray, setSpray, chemicals, sprayers }) {
  const handleSelectSprayer = (x) => {
    setSpray((prevState) => ({
      ...prevState,
      sprayer: [x],
      tankCapacity: x.tankCapacity,
    }));
  };

  const setWaterRate = (value) => {
    if (!Number.isNaN(+value)) {
      setSpray((prevState) => {
        const newState = { ...prevState };
        newState.sprayRate = value;
        return newState; // return the new array
      });
    }
  };

  const handleSelectChemical = (x) => {
    if (spray.chemicals.map((c) => c.chemicalName).includes(x.chemicalName)) {
      setSpray((prevState) => ({
        ...prevState,
        chemicals: [...prevState.chemicals.filter((c) => c.chemicalName !== x.chemicalName)],
      }));
    } else {
      setSpray((prevState) => ({
        ...prevState,
        chemicals: [...prevState.chemicals, x],
      }));
    }
  };

  // Filter chemicals based on spray.weedspray
  const filteredChemicals = spray.weedspray
    ? chemicals.filter((chem) => chem.weedspray)
    : chemicals;
  return (
    <MDBox>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={12} md={12} lg={4}>
          <FormControl size="large" sx={{ minWidth: "100%" }}>
            <InputLabel
              id="target-area"
              sx={
                {
                  // top: spray.chemicals.map((c) => c.chemicalName).length > 0 ? "-10px" : "0px",
                }
              }
            >
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { success } }) => success.main,
                  paddingRight: 2,
                }}
              >
                science
              </Icon>
              Chemical(s)
            </InputLabel>

            <Select
              sx={{ height: "50px" }}
              labelId="operator-label"
              id="operator-select"
              value={spray.chemicals.map((c) => c.chemicalName)}
              label="Chemical(s)__"
            >
              {filteredChemicals.length > 0 ? (
                filteredChemicals.map((chem) => (
                  <MenuItem
                    key={chem.key}
                    value={chem.chemicalName}
                    onClick={() => {
                      handleSelectChemical({ ...chem, batchNo: "" });
                    }}
                  >
                    {chem.chemicalName}
                  </MenuItem>
                ))
              ) : (
                <CircularProgress color="success" />
              )}
            </Select>
          </FormControl>
        </Grid>
        <Card
          sx={{
            marginTop: "20px",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Table>
            {spray.chemicals.length > 0 &&
              spray.chemicals.map((c) => (
                <TableRow>
                  <TableCell>
                    <TextField value={`${c.chemicalName}`} label="Chemical" disabled />
                  </TableCell>
                  <TableCell>
                    <MDBox>
                      <TextField
                        value={`${
                          Math.round(
                            c.ingredients.reduce((sum, ingredient) => {
                              // Parse the ratio as a number
                              const ratio = parseFloat(ingredient.ratio);
                              // Multiply by tank capacity, divide by 100, and add to the sum
                              return sum + (ratio * spray.tankCapacity) / 100;
                            }, 0) * 100
                          ) / 100
                        }`}
                        label="Spray Rate (L/Tank)"
                        disabled
                      />
                    </MDBox>
                  </TableCell>
                  {c.ingredients?.map((ingredient) => (
                    <TableCell key={ingredient.name}>
                      <TextField
                        value={`${ingredient.ratio}`}
                        label={`${ingredient.name}`}
                        disabled
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </Table>
        </Card>

        <Grid item xs={12} md={6} lg={4}>
          <MDBox>
            <MDBox>
              <FormControl size="large" sx={{ minWidth: "100%" }}>
                <InputLabel id="target-area">
                  <Icon
                    sx={{
                      fontWeight: "bold",
                      color: ({ palette: { success } }) => success.main,
                      paddingRight: 2,
                    }}
                  >
                    agriculture
                  </Icon>
                  Sprayer(s)
                </InputLabel>
                <Select
                  sx={{ height: "50px" }}
                  labelId="operator-label"
                  id="operator-select"
                  value={spray.sprayer.map((s) => s.sprayer)}
                  label="Sprayer_____"
                >
                  {sprayers.length > 0 ? (
                    sprayers.map((r) => (
                      <MenuItem
                        key={r.key}
                        value={r.sprayer}
                        onClick={() => {
                          handleSelectSprayer(r);
                        }}
                      >
                        {r.sprayer}
                      </MenuItem>
                    ))
                  ) : (
                    <CircularProgress color="success" />
                  )}
                </Select>
              </FormControl>
            </MDBox>
          </MDBox>
        </Grid>
        <Card
          sx={{
            marginTop: "20px",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Table>
            {spray.sprayer.length > 0 &&
              spray.sprayer.map((s) => (
                <Table>
                  <TableRow>
                    <TableCell>
                      <TextField value={`${s.sprayer}`} label="Sprayer" disabled />
                    </TableCell>
                    <TableCell>
                      <TextField value={`${s.tankCapacity}L`} label="Tank Size" disabled />
                    </TableCell>
                    <TableCell>
                      <MDBox>
                        <TextField
                          value={`${spray.sprayRate}`}
                          label="Water Rate (L/Hec)"
                          onChange={(e) => setWaterRate(e.target.value)}
                        />
                      </MDBox>
                    </TableCell>
                    {spray.chemicals[0]?.ingredients?.map((ingredient) => (
                      <TableCell key={ingredient.name}>
                        <TextField
                          value={`${(ingredient.ratio * s.tankCapacity) / 100}L`}
                          label={`${ingredient.name}`}
                          disabled
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </Table>
              ))}
          </Table>
        </Card>
      </Grid>
    </MDBox>
  );
}

// Typechecking props
Chemicals.propTypes = {
  spray: PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object]))
    .isRequired,
  chemicals: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  sprayers: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  setSpray: PropTypes.func.isRequired,
};

export default Chemicals;
