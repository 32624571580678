// @mui material components
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useLocation, useNavigate } from "react-router-dom";

// React
import { useState, useEffect } from "react";

// firebase functions
import { db } from "firebaseDb";
import { getDocs, query, collection, orderBy, setDoc, doc } from "firebase/firestore";

function Chemical() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { state } = useLocation();
  const [cheminfo, setChemInfo] = useState({
    ...state,
  });
  const [units, setUnits] = useState([]);
  const [targets, setTargets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const saveChemical = () => {
    setLoading(true);
    const chemRef = doc(db, "Chemicals", cheminfo.key);
    setDoc(chemRef, cheminfo, { merge: true }).then(() => {
      setLoading(false);
      navigate("/setup");
    });
  };
  const handleSelectUnit = (x) => {
    setChemInfo((prevState) => ({
      ...prevState,
      unit: x.unit,
      unitshort: x.unitshort,
    }));
  };

  const handleSelectTarget = (x) => {
    setChemInfo((prevState) => ({
      ...prevState,
      target: x.target,
    }));
  };

  const handleSelectCategory = (x) => {
    setChemInfo((prevState) => ({
      ...prevState,
      category: x.category,
    }));
  };
  // Handler to toggle the checkbox
  const handleCheckboxChange = (event) => {
    setChemInfo((prevState) => {
      const defaultIngredients = [
        { name: "Ingredient 1", ratio: 1 },
        { name: "Ingredient 2", ratio: 1 },
        { name: "Ingredient 3", ratio: 1 },
      ];

      const newIngredients = prevState.ingredients
        ? [...prevState.ingredients]
        : defaultIngredients;

      return {
        ...prevState,
        ingredients: newIngredients,
        weedspray: event.target.checked,
      };
    });
  };
  useEffect(() => {
    setLoading(true);
    const unitRef = collection(db, "Units");
    const qu = query(unitRef, orderBy("unit", "asc"));
    getDocs(qu).then((querySnapshot) => {
      const tempunits = [];
      querySnapshot.forEach((docu) => {
        tempunits.push({
          key: docu.id,
          unit: docu.data().unit,
          unitshort: docu.data().shorthand,
        });
      });
      setUnits(tempunits);
    });

    const areaRef = collection(db, "ChemicalTarget");
    const qa = query(areaRef, orderBy("target", "asc"));
    getDocs(qa).then((querySnapshot) => {
      const temptargets = [];
      querySnapshot.forEach((docu) => {
        temptargets.push({
          key: docu.id,
          target: docu.data().target,
        });
      });
      setTargets(temptargets);
    });

    const categoryRef = collection(db, "Categories");
    const qc = query(categoryRef, orderBy("category", "asc"));
    getDocs(qc).then((querySnapshot) => {
      const tempcategories = [];
      querySnapshot.forEach((docu) => {
        tempcategories.push({
          key: docu.id,
          category: docu.data().category,
        });
      });
      setCategories(tempcategories);
      setLoading(false);
    });
  }, []);

  const handleRatioChange = (index, value) => {
    const newIngredients = [...cheminfo.ingredients];
    newIngredients[index].ratio = value;
    setChemInfo((prevState) => ({
      ...prevState,
      ingredients: newIngredients,
    }));
  };

  const handleCostChange = (index, value) => {
    const newIngredients = [...cheminfo.ingredients];
    newIngredients[index].cost = value;
    setChemInfo((prevState) => ({
      ...prevState,
      ingredients: newIngredients,
      cost: newIngredients.reduce((total, item) => total + item.ratio * item.cost, 0) / 100,
    }));
  };

  const handleSelectWeedSprayUnit = (index, x) => {
    const newIngredients = [...cheminfo.ingredients];
    newIngredients[index].unit = x.unit;
    setChemInfo((prevState) => ({
      ...prevState,
      ingredients: newIngredients,
    }));
  };

  const calculateAmount = (ratio, tankSize) =>
    ratio ? ((parseFloat(ratio) * tankSize) / 100).toFixed(2) : "";

  const handleNameChange = (index, value) => {
    setChemInfo((prevState) => {
      const newIngredients = [...prevState.ingredients];
      newIngredients[index].name = value;
      return { ...prevState, ingredients: newIngredients };
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox
        component="li"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={1}
        mt={10}
      >
        {!loading && (
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Chemical Name:
              </MDTypography>
              <TextField
                sx={{ left: "30px", position: "relative" }}
                value={cheminfo.chemicalName}
                onChange={(e) => {
                  setChemInfo((prevState) => ({
                    ...prevState,
                    chemicalName: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "50px",
                  position: "relative",
                }}
              >
                Weedspray Chemical:&nbsp;&nbsp;&nbsp;
              </MDTypography>
              <Checkbox
                checked={cheminfo.weedspray}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "50px",
                  position: "relative",
                }}
                onChange={handleCheckboxChange}
              />
            </MDBox>
            {cheminfo.weedspray ? (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead
                      style={{
                        display: "table-header-group",
                        padding: "1rem 1rem 0 1rem",
                        borderRadius: "0.75rem 0.75rem 0 0",
                      }}
                    >
                      <TableRow>
                        <TableCell>Ingredients</TableCell>
                        <TableCell>Cost (per Unit) </TableCell>
                        <TableCell>Unit </TableCell>
                        <TableCell>Ratio (per 100L)</TableCell>
                        <TableCell>500L Tank</TableCell>
                        <TableCell>400L Tank</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cheminfo.ingredients.map((ingredient, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                          <TableCell>
                            <TextField
                              value={ingredient.name}
                              onChange={(e) => handleNameChange(index, e.target.value)}
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={ingredient.cost}
                              onChange={(e) => handleCostChange(index, e.target.value)}
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <MDBox
                              display="flex"
                              alignItems={{ xs: "flex-start", sm: "center" }}
                              flexDirection={{ xs: "column", sm: "row" }}
                              mb={2}
                              mt={2}
                            >
                              <Select
                                sx={{
                                  height: "50px",
                                  minWidth: "183px",
                                  position: "relative",
                                }}
                                value={ingredient.unit}
                              >
                                {units.length > 0 ? (
                                  units.map((unit) => (
                                    <MenuItem
                                      key={unit.key}
                                      value={unit.unit}
                                      onClick={() => {
                                        handleSelectWeedSprayUnit(index, unit);
                                      }}
                                    >
                                      {unit.unit}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <CircularProgress color="success" />
                                )}
                              </Select>
                            </MDBox>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={ingredient.ratio}
                              type="number"
                              onChange={(e) => handleRatioChange(index, e.target.value)}
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={ingredient.ratio}
                              type="number"
                              onChange={(e) => handleRatioChange(index, e.target.value)}
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>{calculateAmount(ingredient.ratio, 500)}</TableCell>
                          <TableCell>{calculateAmount(ingredient.ratio, 400)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mt={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    sx={{ fontSize: "1rem", verticalAlign: "center" }}
                  >
                    Mix Cost (per Unit):&nbsp;&nbsp;&nbsp;
                  </MDTypography>
                  <TextField
                    type="number"
                    value={
                      cheminfo.ingredients.reduce(
                        (total, item) => total + item.ratio * item.cost,
                        0
                      ) / 100
                    }
                  />
                </MDBox>
              </>
            ) : (
              <>
                {" "}
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    mr={2}
                    sx={{
                      fontSize: "1rem",
                      verticalAlign: "center",
                      left: "30px",
                      position: "relative",
                    }}
                  >
                    Active Ingredient:
                  </MDTypography>
                  <TextField
                    sx={{ left: "30px", position: "relative" }}
                    value={cheminfo.activeIngredient}
                    onChange={(e) => {
                      setChemInfo((prevState) => ({
                        ...prevState,
                        activeIngredient: e.target.value,
                      }));
                    }}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    mr={2}
                    sx={{
                      fontSize: "1rem",
                      verticalAlign: "center",
                      left: "30px",
                      position: "relative",
                    }}
                  >
                    CropSure ID:
                  </MDTypography>
                  <TextField
                    sx={{ left: "30px", position: "relative" }}
                    value={cheminfo.cropSureID}
                    onChange={(e) => {
                      setChemInfo((prevState) => ({
                        ...prevState,
                        cropSureID: e.target.value,
                      }));
                    }}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    sx={{ fontSize: "1rem", verticalAlign: "center" }}
                  >
                    Chemical Cost (per Unit):&nbsp;&nbsp;&nbsp;
                  </MDTypography>
                  <TextField
                    type="number"
                    value={cheminfo.cost}
                    onChange={(e) => {
                      setChemInfo((prevState) => ({
                        ...prevState,
                        cost: e.target.value,
                      }));
                    }}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    sx={{
                      fontSize: "1rem",
                      verticalAlign: "center",
                      left: "78px",
                      position: "relative",
                    }}
                  >
                    Unit:&nbsp;&nbsp;&nbsp;
                  </MDTypography>

                  <Select
                    sx={{ height: "50px", minWidth: "183px", left: "78px", position: "relative" }}
                    value={cheminfo.unit}
                  >
                    {units.length > 0 ? (
                      units.map((unit) => (
                        <MenuItem
                          key={unit.key}
                          value={unit.unit}
                          onClick={() => {
                            handleSelectUnit(unit);
                          }}
                        >
                          {unit.unit}
                        </MenuItem>
                      ))
                    ) : (
                      <CircularProgress color="success" />
                    )}
                  </Select>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    sx={{
                      fontSize: "1rem",
                      verticalAlign: "center",
                      left: "70px",
                      position: "relative",
                    }}
                  >
                    Target:&nbsp;&nbsp;&nbsp;
                  </MDTypography>

                  <Select
                    sx={{ height: "50px", minWidth: "183px", left: "70px", position: "relative" }}
                    value={cheminfo.target}
                  >
                    {targets.length > 0 ? (
                      targets.map((target) => (
                        <MenuItem
                          key={target.key}
                          value={target.target}
                          onClick={() => {
                            handleSelectTarget(target);
                          }}
                        >
                          {target.target}
                        </MenuItem>
                      ))
                    ) : (
                      <CircularProgress color="success" />
                    )}
                  </Select>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    sx={{
                      fontSize: "1rem",
                      verticalAlign: "center",
                      left: "60px",
                      position: "relative",
                    }}
                  >
                    Category:&nbsp;&nbsp;&nbsp;
                  </MDTypography>

                  <Select
                    sx={{ height: "50px", minWidth: "183px", left: "60px", position: "relative" }}
                    value={cheminfo.category}
                  >
                    {categories.length > 0 ? (
                      categories.map((cat) => (
                        <MenuItem
                          key={cat.key}
                          value={cat.category}
                          onClick={() => {
                            handleSelectCategory(cat);
                          }}
                        >
                          {cat.category}
                        </MenuItem>
                      ))
                    ) : (
                      <CircularProgress color="success" />
                    )}
                  </Select>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="caption"
                    color="text"
                    sx={{
                      fontSize: "1rem",
                      verticalAlign: "center",
                      left: "50px",
                      position: "relative",
                    }}
                  >
                    Default Spray Rate:&nbsp;&nbsp;&nbsp;
                  </MDTypography>

                  <TextField
                    sx={{ maxWidth: "100px", left: "50px", position: "relative" }}
                    type="number"
                    value={cheminfo.qtyPerHectare}
                    onChange={(e) => {
                      setChemInfo((prevState) => ({
                        ...prevState,
                        qtyPerHectare: e.target.value,
                      }));
                    }}
                  />
                  <MDTypography
                    variant="caption"
                    color="text"
                    ml={1}
                    sx={{
                      fontSize: "1rem",
                      verticalAlign: "center",
                      left: "50px",
                      position: "relative",
                    }}
                  >
                    {`${cheminfo.unit}s per Ha`}
                  </MDTypography>
                </MDBox>
              </>
            )}
          </MDBox>
        )}
        {loading && <CircularProgress />}
      </MDBox>
      <MDBox mb={1} lineHeight={0} textAlign="center">
        <MDButton
          variant="contained"
          color="success"
          disabled={loading}
          onClick={() => {
            saveChemical();
          }}
        >
          <Icon>save</Icon>&nbsp;save
        </MDButton>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Chemical;
