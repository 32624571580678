// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// @mui icons
import SaveIcon from "@mui/icons-material/Save";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InvoiceCard from "examples/Cards/InfoCards/InvoiceCard";
import PurchaseCard from "examples/Cards/InfoCards/PurchaseCard";
import PurchaseList from "examples/Lists/PurchaseList";
import CreateInvoiceSprayList from "examples/Lists/CreateInvoiceSprayList";
// Overview page components
import Header from "layouts/createinvoice/components/Header";

import { useNavigate, useLocation } from "react-router-dom";

// React
import { useEffect, useState } from "react";

// Firebase
import { collection, query, where, getDocs, addDoc, getDoc, doc } from "firebase/firestore";
import { db } from "firebaseDb";

function CreateInvoice() {
  const [invoiceData, setInvoiceData] = useState({ sprays: [], purchases: [] });
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const location = useLocation();
  const { data } = location.state;

  const navigate = useNavigate();

  const saveinvoice = () => {
    if (invoiceData.sprays.length > 0) {
      setLoading(true);
      const invoiceRef = collection(db, "Invoices");
      const st = startDate.$d ? startDate.$d : startDate;
      const en = endDate.$d ? endDate.$d : endDate;
      addDoc(invoiceRef, {
        ...invoiceData,
        startDate: st,
        endDate: en,
        chemicalSnapshot: data,
      }).then(() => {
        setLoading(false);
        navigate("/billing");
      });
    }
  };
  const totalChemical = (amount, measure, hectares, waterRate, tankCapacity) => {
    const tanksperHectare = waterRate / tankCapacity;
    if (measure === "Tank") {
      return Math.round(100 * amount * tanksperHectare * hectares) / 100;
    }
    if (measure === "Hectare") {
      return Math.round(100 * Number(amount) * hectares) / 100;
    }
    if (measure === "1000 L") {
      return Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100;
    }
    return null;
  };

  useEffect(() => {
    if (startDate && endDate) {
      setLoading(true);
      const sprayRef = collection(db, "Sprays");
      const weedSprayRef = collection(db, "WeedsprayInvoices");

      const latestSpraysQuery = query(
        sprayRef,
        where("sprayDate", ">=", startDate.$d ? startDate.$d : startDate),
        where("sprayDate", "<=", endDate.$d ? endDate.$d : endDate)
      );

      const latestWeedSpraysQuery = query(
        weedSprayRef,
        where("date", ">=", startDate.$d ? startDate.$d : startDate),
        where("date", "<=", endDate.$d ? endDate.$d : endDate)
      );

      // Fetch latest sprays
      getDocs(latestSpraysQuery).then((qs) => {
        const tempdata = [];
        let chemcost = 0;

        qs.forEach((docu) => {
          docu.data().chemicals.forEach((chem) => {
            getDoc(doc(db, "Chemicals", chem.key)).then((chemical) => {
              chemcost +=
                totalChemical(
                  chem.sprayRate,
                  chem.measure,
                  docu.data().hectares,
                  chem.unit,
                  docu.data().waterRate,
                  docu.data().tankCapacity
                ) * chemical.data().cost;
            });
          });

          if (!docu.data().archive) {
            tempdata.push({ ...docu.data(), key: docu.id, sprayCost: chemcost });
          }
        });

        // Fetch latest weed sprays
        getDocs(latestWeedSpraysQuery).then((weedQs) => {
          const weedTempdata = [];

          // Loop through each document in weedQs
          weedQs.forEach((weedDoc) => {
            const weedData = weedDoc.data();

            // Only process non-archived documents
            if (!weedData.archive) {
              // Check if an entry with the same siteCode, date, and chemicalName already exists
              const existingEntry = weedTempdata.find(
                (entry) =>
                  entry.siteCode === weedData.siteCode &&
                  entry.sprayDate.seconds === weedData.date.seconds && // Comparing by date.seconds
                  entry.chemicalName === weedData.chemicalName
              );

              if (existingEntry) {
                // If a matching entry is found, sum up the values
                existingEntry.chemicalcost += weedData.chemicalcost || 0; // Ensure no undefined issues
                existingEntry.hectares += weedData.hectares || 0;
                existingEntry.totalused += weedData.totalused || 0;
              } else {
                // If no matching entry is found, push a new entry to weedTempdata
                weedTempdata.push({
                  ...weedData,
                  sprayDate: weedData.date, // Using date as sprayDate
                  key: weedDoc.id,
                  chemicalcost: weedData.chemicalcost || 0, // Ensure proper initialization
                  hectares: weedData.hectares || 0,
                  totalused: weedData.totalused || 0,
                });
              }
            }
          });

          // Merge both spray and weed spray data
          const mergedSprays = [...tempdata, ...weedTempdata].reduce((acc, spray) => {
            const siteName = spray.siteName || "Unknown Site";
            const siteCode = spray.siteCode || "Unknown Code";
            const orchardGUID = spray.orchardGUID || "";

            const existingSpray = acc.find((item) => item.siteName === siteName);

            if (existingSpray) {
              existingSpray.sprays.push({
                ...spray,
                date: spray.date ? spray.date.seconds : "No date provided",
              });
            } else {
              acc.push({
                siteName,
                siteCode,
                orchardGUID,
                sprays: [
                  {
                    ...spray,
                    date: spray.date ? spray.date.seconds : "No date provided", // Fallback for undefined date
                  },
                ],
              });
            }
            return acc;
          }, []);

          setInvoiceData({ sprays: mergedSprays, purchases: [] });
          setLoading(false);
        });
      });
    }
  }, [startDate, endDate]);

  if (!loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Header>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                {invoiceData && (
                  <InvoiceCard
                    setInvoiceData={setInvoiceData}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    title="Choose Date Range"
                    info={{
                      data: invoiceData.sprays,
                      start: startDate,
                      end: endDate,
                    }}
                    shadow={false}
                  />
                )}
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} md={8} xl={8}>
                {invoiceData && data && (
                  <PurchaseCard
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    data={data}
                    title="Add Purchases"
                    info={{
                      chemical: "",
                      unit: "",
                      amount: "",
                      cost: "",
                    }}
                    shadow={false}
                  />
                )}
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} xl={12}>
                {invoiceData.purchases?.length > 0 && (
                  <PurchaseList
                    title="Purchases"
                    invoiceData={invoiceData}
                    shadow
                    setInvoiceData={setInvoiceData}
                    edit
                  />
                )}
              </Grid>
              <Grid item xs={12} xl={12}>
                {invoiceData.sprays?.length > 0 && (
                  <CreateInvoiceSprayList
                    title="Spray Billing Info"
                    invoiceData={invoiceData.sprays}
                    chemicals={data}
                    shadow
                  />
                )}
              </Grid>
            </Grid>
            <Box mt={5} mb={3} textAlign="center">
              <MDButton
                variant="contained"
                color="success"
                startIcon={<SaveIcon />}
                onClick={() => {
                  saveinvoice();
                }}
              >
                Save Invoice
              </MDButton>
            </Box>
          </MDBox>
        </Header>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Box mt={5} mb={3} textAlign="center">
          <CircularProgress color="success" />
        </Box>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateInvoice;
