// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PurchaseList from "examples/Lists/PurchaseList";
import InvoiceSprayList from "examples/Lists/InvoiceSprayList";
import InvoiceDetailCard from "examples/Cards/InfoCards/InvoiceDetailCard";
// Overview page components
import Header from "layouts/createinvoice/components/Header";

// React
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

// Firebase
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "firebaseDb";

function InvoiceDetails() {
  const { invoiceID } = useParams();
  const [invoiceData, setInvoiceData] = useState({ sprays: [], purchases: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const invoiceRef = doc(db, "Invoices", invoiceID);

    // Set up the snapshot listener
    const unsubscribe = onSnapshot(invoiceRef, (docSnap) => {
      if (docSnap.exists()) {
        setInvoiceData(docSnap.data());
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    });

    // Cleanup listener when component unmounts
    return () => unsubscribe();
  }, [invoiceID]); // Depend on invoiceID to re-run when it changes

  if (!loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Header>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} xl={12}>
                {invoiceData.startDate && (
                  <InvoiceDetailCard
                    title="Invoice"
                    invoiceData={invoiceData}
                    invoiceID={invoiceID}
                    shadow
                  />
                )}
              </Grid>
              <Grid item xs={12} xl={12}>
                {invoiceData.purchases?.length > 0 && (
                  <PurchaseList
                    title="Purchases"
                    invoiceData={invoiceData}
                    shadow
                    setInvoiceData={setInvoiceData}
                  />
                )}
              </Grid>
              <Grid item xs={12} xl={12}>
                {invoiceData.sprays?.length > 0 && (
                  <InvoiceSprayList
                    title="Spray Billing Info"
                    invoiceData={invoiceData}
                    chemicals={invoiceData.chemicalSnapshot}
                    shadow
                    setInvoiceData={setInvoiceData}
                  />
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Header>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Box mt={5} mb={3} textAlign="center">
          <CircularProgress color="success" />
        </Box>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default InvoiceDetails;
