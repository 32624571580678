// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/WeedSprayDataTable";

// React
import { useEffect, useState } from "react";

// Router
import { useNavigate } from "react-router-dom";
// Data
import spraysTableData from "layouts/weedsprays/data/weedspraysTableData";
// Firebase
import { db } from "firebaseDb";
import {
  getDocs,
  collection,
  query,
  where,
  setDoc,
  doc,
  // updateDoc,
} from "firebase/firestore";

// import { saveAs } from "file-saver";

function Tables() {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sprayID, setID] = useState("");
  const [status, setStatus] = useState("Ready to Spray");

  const navigate = useNavigate();

  const handleOpenDialog = (id) => {
    setOpenDialog(true);
    setID(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Delete Spray
  const deleteSpray = () => {
    setLoading(true);
    const sprayRef = collection(db, "Weedsprays");
    const q = query(sprayRef, where("sprayInstructionId", "==", sprayID));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((docu) => {
        setDoc(doc(db, "Weedsprays", docu.id), { archive: true }, { merge: true }).then(() => {
          handleCloseDialog();
          setLoading(false);
        });
      });
    });
  };

  const { columns, rows, clickEvent } = spraysTableData(handleOpenDialog, status);
  const [rowInfo, setRowInfo] = useState(
    rows
      .filter((row) => row.status === "Ready to Spray" && row.date === "")
      .sort((a, b) => b.sprayID - a.sprayID)
  );

  const sortObjectsByDate = (objectArray) => {
    // Custom sorting function
    const customSort = (a, b) => {
      const dateA = new Date(`${a.date.split("/").reverse().join("-")}T00:00:00Z`).getTime();
      const dateB = new Date(`${b.date.split("/").reverse().join("-")}T00:00:00Z`).getTime();
      return dateB - dateA;
    };

    // Sort the array of objects using the custom sorting function
    objectArray.sort(customSort);

    return objectArray;
  };

  useEffect(() => {
    if (status === "Ready to Spray") {
      setRowInfo(() =>
        rows
          .filter((row) => row.status === status && row.date === "")
          .sort((a, b) => b.sprayID - a.sprayID)
      );
    }
    if (status === "Sprayed") {
      setRowInfo(() =>
        sortObjectsByDate(rows.filter((row) => row.status === "Ready to Spray" && row.date !== ""))
      );
    }
  }, [status, rows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Record?</DialogTitle>
        <DialogContent>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this spray record?
            </DialogContentText>
          )}
        </DialogContent>

        {!loading && (
          <DialogActions>
            <MDButton onClick={handleCloseDialog}>No</MDButton>
            <MDButton
              onClick={() => {
                deleteSpray();
              }}
              autoFocus
            >
              Yes
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Weed Sprays Table
                </MDTypography>
                <MDBox justifyContent="center" display="flex">
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Ready to Spray" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Ready to Spray");
                    }}
                  >
                    Ready to Spray
                    <Icon>checklist</Icon>
                  </MDButton>
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Sprayed" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Sprayed");
                    }}
                  >
                    Sprayed
                    <Icon>waterdrop</Icon>
                  </MDButton>
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color="success"
                    onClick={() => {
                      navigate("/weedsprays/createweedspray");
                    }}
                  >
                    Create New
                    <Icon>grass</Icon>
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {rowInfo.length > 0 && (
                  <DataTable
                    table={{ columns, rows: rowInfo, clickEvent }}
                    entriesPerPage
                    showTotalEntries
                    canSearch
                    isSorted={false}
                  />
                )}
                {rowInfo.length === 0 && (
                  <MDBox justifyContent="center" display="flex" marginBottom="20px">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
