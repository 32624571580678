/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React from "react";
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Images
import backGroundImage from "../../../../../assets/images/logo-ct-dark.png";
import signature from "../../../../../assets/images/katesignature.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    padding: 30,
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    marginRight: 20,
  },
  date: {
    fontSize: 16,
    marginBottom: 10,
  },
  subheader: {
    width: "100%",
    fontSize: 14,
    marginBottom: 5,
    paddingTop: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    margin: "auto",
  },
  content: {
    fontSize: 14,
    marginBottom: 20,
  },
  sprayer: {
    fontSize: 14,
    marginBottom: 20,
    marginTop: 20,
    textDecoration: "underline",
  },
  settings: {
    fontSize: 12,
    marginBottom: 5,
  },
  image: {
    width: 40,
    marginBottom: 5,
    borderRadius: 10,
    maxHeight: 50,
  },
  signature: {
    width: 80,
    marginLeft: 20,
  },
  viewer: {
    width: "100%",
    height: 700,
    marginBottom: 20,
    borderRadius: 10,
  },
  header: {
    flexDirection: "row",
    paddingTop: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 5,
  },
  tableSignature: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    marginTop: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableRowSignature: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColBlocks: {
    width: "14.2857%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColBlockTanks: {
    width: "14.2857%",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 1,
    borderTopWidth: 1,
  },
  tableColChem: {
    width: "16.6667%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColTanks: {
    width: "8.3333%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSignature: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  tableSprayerRow: {
    margin: "auto",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableSprayerCol: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "black",
  },
  tableColHeaderBlocks: {
    width: "14.2857%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "black",
  },
  tableColOrchardHeaderBlocks: {
    width: "28.5714%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "black",
  },
  tableColHeaderChem: {
    width: "16.6667%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "grey",
    height: "40px",
  },
  tableColHeaderTanks: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "grey",
    height: "25px",
  },
  tableColHeaderPPE: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "white",
    height: "25px",
  },
  tableCell: {
    margin: "auto",
    marginTop: 4,
    fontSize: 10,
  },
  tableCellHec: {
    margin: "auto",
    marginTop: 4,
    fontSize: 7,
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderBlocks: {
    margin: "auto",
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderChem: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderTanks: {
    margin: "auto",
    marginTop: 5,
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderPPE: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
  },
  tableCellPPE: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
  },
  column: {
    width: "33.33%",
    marginBottom: 10,
    paddingRight: 10,
  },
  cell: {
    marginBottom: 1,
  },
});

const PrintableReport = React.forwardRef(({ data }, ref) => {
  // Filter for checked blocks
  const checkedOrchards = data.orchards.filter((item) => item.checked);
  // Filter for checked varieties within each checked block
  const filteredData = checkedOrchards.map((item) => {
    item.blocks = item.blocks.filter((block) => block.checked);
    return item;
  });

  // Calculate Hectares
  let hecsum = 0;
  /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
  for (let i = 0; i < filteredData?.length; i++) {
    for (let j = 0; j < filteredData[i]?.blocks?.length; j++) {
      for (let k = 0; k < filteredData[i]?.blocks[j]?.varieties?.length; k++) {
        if (filteredData[i]?.blocks[j]?.checked) {
          hecsum += Number(filteredData[i]?.blocks[j]?.varieties[k]?.hectares);
        }
      }
    }
  }
  const totalHectares = Math.round(hecsum * 100) / 100;

  // Calculate Tanks
  const totalTanks = ((data.sprayRate * totalHectares) / data.tankCapacity).toFixed(1);

  const calcHec = (block) => {
    // Calculate Hectares
    let hecsumBlock = 0;
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

    for (let k = 0; k < block.varieties?.length; k++) {
      hecsumBlock += Number(block.varieties[k]?.hectares);
    }
    const blockHectares = Math.round(hecsumBlock * 100) / 100;
    return blockHectares;
  };

  const calcTanks = (block) => {
    // Calculate Hectares
    let hecsumBlock = 0;
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

    for (let k = 0; k < block.varieties?.length; k++) {
      hecsumBlock += Number(block.varieties[k]?.hectares);
    }
    const blockTanks = (data.sprayRate * hecsumBlock) / data.tankCapacity;
    const roundedBlockTanks = (Math.round(blockTanks * 100) / 100).toFixed(1);
    return roundedBlockTanks;
  };

  const calcVolume = (block) => {
    // Calculate Hectares
    let hecsumBlock = 0;
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

    for (let k = 0; k < block.varieties?.length; k++) {
      hecsumBlock += Number(block.varieties[k]?.hectares);
    }
    const blockVolume = (data.sprayRate * hecsumBlock).toFixed(0);
    return blockVolume;
  };
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View ref={ref}>
            <View style={styles.header}>
              <Image src={backGroundImage} style={styles.image} />
              <Text style={styles.title}>
                Weed Spray - {data.area} {data.siteCode}
              </Text>
              <Text style={styles.date}>Instruction #: {data.sprayInstructionId}</Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.subheader}>Sprayer Settings</Text>
            </View>
            <View style={styles.tableSprayerRow}>
              {data.sprayer.map((tractor) => (
                <View
                  style={{
                    ...styles.tableSprayerCol,
                    width: `${JSON.stringify(100 / (data.sprayer.length + 1))}%`,
                  }}
                >
                  <Text style={styles.sprayer}>{tractor.sprayer}</Text>
                  <Text style={styles.settings}>Capacity: {tractor.tankCapacity}</Text>
                </View>
              ))}

              <View
                style={{
                  ...styles.tableSprayerCol,
                  width: `${JSON.stringify(100 / (data.sprayer.length + 1))}%`,
                  backgroundColor: "lightblue",
                  paddingLeft: 6,
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <Text style={styles.sprayer}>Water Settings</Text>
                <Text style={styles.settings}>Water Rate: {data.sprayRate}</Text>
                <Text style={styles.settings}>Water Capacity: {data.tankCapacity}L</Text>
                <Text style={styles.settings}>Total Tanks: {totalTanks}</Text>
              </View>
            </View>
            <View
              style={{
                ...styles.tableSprayerCol,
                width: `100%`,
                backgroundColor: "lightgrey",
                paddingLeft: 6,
                paddingTop: 6,
                marginTop: 6,
                borderRadius: 5,
              }}
            >
              <Text style={styles.title}>Notes - {data.notes}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Chemical</Text>
                </View>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Usage Rate</Text>
                  <Text style={styles.tableCellHeaderChem}>(per Tank)</Text>
                </View>
                {data.chemicals[0].ingredients.map((i) => (
                  <View style={styles.tableColHeaderChem}>
                    <Text style={styles.tableCellHeaderChem}>{i.name}</Text>
                    <Text style={styles.tableCellHeaderChem}>(per Tank)</Text>
                  </View>
                ))}
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Total Actual</Text>
                </View>
              </View>
              {data?.chemicals?.map((chem) => (
                <View>
                  <View style={{ ...styles.tableRow, height: "20px" }}>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    >
                      <Text
                        style={{
                          ...styles.tableCell,
                        }}
                      >
                        {chem.chemicalName}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    >
                      <Text style={styles.tableCell}>
                        {`${
                          Math.round(
                            chem.ingredients.reduce((sum, ingredient) => {
                              // Parse the ratio as a number
                              const ratio = parseFloat(ingredient.ratio);
                              // Multiply by tank capacity, divide by 100, and add to the sum
                              return sum + (ratio * data.tankCapacity) / 100;
                            }, 0) * 100
                          ) / 100
                        }L`}
                      </Text>
                    </View>
                    {chem.ingredients.map((i) => (
                      <View
                        style={{
                          ...styles.tableColChem,
                        }}
                      >
                        <Text style={styles.tableCell}>
                          {`${(i.ratio * data.tankCapacity) / 100}${i.unit}`}
                        </Text>
                      </View>
                    ))}
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    />
                  </View>
                  <View style={{ ...styles.tableRow }}>
                    <View
                      style={{
                        ...styles.tableColChem,
                        backgroundColor: "lightgrey",
                      }}
                    />
                    <View
                      style={{
                        ...styles.tableColChem,
                        width: "83.33%",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.tableCell,
                          fontSize: 12,
                          margin: 6,
                        }}
                      >
                        Batch No:
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderTanks}>
                  <Text style={styles.tableCellHeaderPPE}>
                    Handling Precautions: You must use the below PPE while completing this spray
                    application.
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                {[...Array(5)].map((_, index) => (
                  <View key={index} style={styles.tableColHeaderPPE}>
                    <Text style={styles.tableCellPPE}>
                      {data.PPE[index] ? data.PPE[index] : ""}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            <View
              style={{ ...styles.tableSignature, top: 250 - 25 * (data.operatorWorker.length - 1) }}
            >
              <View style={{ ...styles.tableRow, marginLeft: 2, marginTop: 4, marginBottom: 4 }}>
                <View style={styles.tableColHeaderSignature}>
                  <Text style={styles.tableCellHeaderSignature}>Operator(s):</Text>
                </View>
              </View>
              {data?.operatorWorker?.map((op) => (
                <View style={{ ...styles.tableRow, marginTop: 15 }}>
                  <View
                    style={{
                      ...styles.tableColSignature,
                    }}
                  >
                    <Text style={styles.tableCell}>{op.toUpperCase()}</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableColSignature,
                      borderBottomWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>Date:________________ </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableColSignature,
                      borderBottomWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>Signature:________________</Text>
                  </View>
                </View>
              ))}
              <View style={{ ...styles.tableRow, marginTop: 15 }}>
                <View
                  style={{
                    ...styles.tableColSignature,
                  }}
                >
                  <Text style={styles.tableCell}>AUTHORISED BY: KATE THOMAS</Text>
                </View>
                <View
                  style={{
                    ...styles.tableColSignature,
                    borderBottomWidth: 0,
                    marginRight: 0,
                  }}
                >
                  <Text style={{ ...styles.tableCell, marginRight: 0 }}>Signature:</Text>
                </View>
                <View
                  style={{
                    ...styles.tableColSignature,
                    borderBottomWidth: 1,
                    marginLeft: 5,
                  }}
                >
                  <Image src={signature} style={styles.signature} />
                </View>
              </View>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.page}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableColHeaderBlocks, width: "28.5714%" }}>
                  <Text style={styles.tableCellHeader}>Orchard</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Block</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Litres</Text>
                </View>
                <View style={{ ...styles.tableColHeaderBlocks, width: "42.8571%" }}>
                  <Text style={styles.tableCellHeader}>Checked</Text>
                </View>
                <View
                  style={{
                    ...styles.tableColHeaderBlocks,
                    width: "6%",
                  }}
                >
                  <Text style={styles.tableCellHeader}>x</Text>
                </View>
              </View>
              {filteredData.map((site) =>
                site.blocks.map((block, index) => (
                  <View style={{ ...styles.tableRow }}>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === site.blocks.length - 1 ? 3 : 0,
                        width: "28.5714%",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.tableCell,
                          top: 14 * (site.blocks.length - 1),
                          fontSize: 10,
                        }}
                      >
                        {index === 0 ? site.siteName : ""}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === site.blocks.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell}>{`${block.blockCode}`}</Text>
                      <Text style={styles.tableCellHec}>{`(${calcHec(block)} hec)`}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === site.blocks.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell}>{calcVolume(block)}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === site.blocks.length - 1 ? 3 : 1,
                        width: "42.8571%",
                        flexDirection: "row",
                      }}
                    >
                      {Array.from({ length: Math.ceil(calcTanks(block)) }).map((_, tankIndex) => (
                        // const totalBoxes = Math.ceil(calcTanks(block));
                        // const fractionalPart = calcTanks(block) - Math.floor(calcTanks(block));

                        // let partTank = 25;
                        // if (fractionalPart > 0) {
                        //   partTank = fractionalPart * boxWidth;
                        // }

                        // Check if this is the last box
                        // const isLastBox = tankIndex === totalBoxes - 1;
                        // if (isLastBox) {
                        //   return (
                        //     <View
                        //       key={tankIndex}
                        //       style={{
                        //         ...styles.tableColBlockTanks,
                        //         width: boxWidth,
                        //         height: 25,
                        //         margin: 1,
                        //         backgroundColor: "lightgreen",
                        //       }}
                        //     >
                        //       <View
                        //         key={tankIndex}
                        //         style={{
                        //           width: totalBoxes > 7 ? boxWidth - 5 : boxWidth - 3,
                        //           height: partTank > 22 ? 24 - partTank : 23 - partTank,
                        //           backgroundColor: "white",
                        //         }}
                        //       />
                        //     </View>
                        //   );
                        // }
                        <View
                          key={tankIndex}
                          style={{
                            ...styles.tableColBlockTanks,
                            width: 25,
                            height: 25,
                            margin: 1,
                          }}
                        >
                          <Text style={styles.tableCell}>{tankIndex + 1}</Text>
                        </View>
                      ))}
                    </View>

                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === site.blocks.length - 1 ? 3 : 1,
                        width: "6%",
                      }}
                    >
                      <Text style={styles.tableCell} />
                    </View>
                  </View>
                ))
              )}
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Totals</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>{totalHectares} Hectares</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>{totalTanks} Tanks</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
});

// Typechecking props
PrintableReport.propTypes = {
  data: PropTypes.objectOf.isRequired,
};

export default PrintableReport;
