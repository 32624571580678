/* eslint-disable no-plusplus */
// @mui material components
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import OrchardListEdit from "examples/Lists/OrchardListEdit";
import { useLocation, useNavigate } from "react-router-dom";

// React
import { useState } from "react";

// firebase functions
import { db } from "firebaseDb";
import { doc, writeBatch } from "firebase/firestore";

function Chemical() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { state } = useLocation();
  const [orchardInfo, setOrchardInfo] = useState(state.site);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const batch = writeBatch(db);
  const saveOrchard = () => {
    setLoading(true);

    try {
      for (let i = 0; i < orchardInfo.blocks.length; i++) {
        for (let j = 0; j < orchardInfo.blocks[i].varieties.length; j++) {
          const variety = orchardInfo.blocks[i].varieties[j];

          if (
            !variety.key ||
            !orchardInfo.orchardGUID ||
            !orchardInfo.cropSureID ||
            !orchardInfo.blocks[i].cropSureBlockID
          ) {
            throw new Error(`Missing required fields for variety at index (${i},${j})`);
          }

          const orcRef = doc(db, "Orchards", variety.key);

          // Queue the set operation in the batch
          batch.set(
            orcRef,
            {
              ...variety,
              orchardGUID: orchardInfo.orchardGUID,
              cropSureID: orchardInfo.cropSureID,
              cropSureBlockID: orchardInfo.blocks[i].cropSureBlockID,
              blockName: orchardInfo.blocks[i].blockName,
            },
            { merge: true }
          );
        }
      }

      // Commit the batch to Firestore
      batch
        .commit()
        .then(() => {
          console.log("Batch write completed successfully.");
          setLoading(false);
          navigate("/setup");
        })
        .catch((error) => {
          alert("Error performing batch write");
          console.error(error);
          setLoading(false);
        });
    } catch (error) {
      alert("Error preparing batch write:", error);
      console.error(error);
      setLoading(false);
    }
  };

  const archiveOrchard = () => {
    setLoading(true);

    try {
      for (let i = 0; i < orchardInfo.blocks.length; i++) {
        for (let j = 0; j < orchardInfo.blocks[i].varieties.length; j++) {
          const variety = orchardInfo.blocks[i].varieties[j];

          const orcRef = doc(db, "Orchards", variety.key);

          // Queue the set operation in the batch
          batch.set(
            orcRef,
            {
              archive: true,
            },
            { merge: true }
          );
        }
      }

      // Commit the batch to Firestore
      batch
        .commit()
        .then(() => {
          console.log("Batch write completed successfully.");
          setLoading(false);
          navigate("/setup");
        })
        .catch((error) => {
          alert("Error performing batch write");
          console.error(error);
          setLoading(false);
        });
    } catch (error) {
      alert("Error preparing batch write:", error);
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox
        component="li"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={1}
        mt={10}
      >
        {!loading && (
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Orchard:
              </MDTypography>
              <TextField
                sx={{ left: "38px", position: "relative" }}
                value={orchardInfo.siteName}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    siteName: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  position: "relative",
                }}
              >
                Archive Orchard:
              </MDTypography>
              <MDButton
                variant="contained"
                color="error"
                disabled={loading}
                onClick={() => {
                  const confirmDelete = window.confirm(
                    "Are you sure you want to delete this orchard?"
                  );
                  if (confirmDelete) {
                    archiveOrchard();
                  }
                }}
              >
                Delete Orchard
              </MDButton>
            </MDBox>

            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                OrchardGUID:
              </MDTypography>
              <TextField
                sx={{ left: "38px", position: "relative" }}
                value={orchardInfo.orchardGUID}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    orchardGUID: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                CropSure ID:
              </MDTypography>
              <TextField
                sx={{ left: "38px", position: "relative" }}
                value={orchardInfo.cropSureID}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    cropSureID: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Site Code:
              </MDTypography>
              <TextField
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "40px",
                  position: "relative",
                }}
                type="text"
                value={orchardInfo.siteCode}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    siteCode: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox>
              {orchardInfo && (
                <OrchardListEdit
                  title="Block List"
                  orchardInfo={orchardInfo}
                  setOrchardInfo={setOrchardInfo}
                  varieties={state.varieties}
                />
              )}
            </MDBox>
          </MDBox>
        )}
        {loading && <CircularProgress />}
      </MDBox>
      <MDBox mb={1} lineHeight={0} textAlign="center">
        <MDButton
          variant="contained"
          color="success"
          disabled={loading}
          onClick={() => {
            saveOrchard();
          }}
        >
          <Icon>save</Icon>&nbsp;save
        </MDButton>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Chemical;
